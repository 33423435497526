/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

// Lib UI components
import { Container } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import TitlePage from '../page-components/News/TitlePage/index';
import NewsSection from '../page-components/News/NewsSection/index';

// Style
import '../page-styles/News.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function News({ path }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const { titlePage } = t('News', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="News" />
      <Container className="News" fluid>
        <TitlePage NamePage={titlePage} />
        <NewsSection path={path} />
      </Container>
    </PageLayout>
  );
}

News.propTypes = {
  path: PropTypes.string,
};

News.defaultProps = {
  path: '/news/',
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["News", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default News;
