/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
import axios from 'axios';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// Helpers & utils
import { convertDigitIn } from '../../../shared/Helpers';
// Style
import './index.scss';

// APIs
import { API_NEWS, API_UPLOADS } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function NewsSection() {
  /* ******************************** CONSTANTS ******************************* */
  const PageNumbers = [1];
  /* ********************************** HOOKS ********************************* */
  // State
  const [activePage, setActivePage] = useState(1);
  const [newsList, setNewsList] = useState();
  // localization

  const { language } = useI18next();
  /* --------------------------------- HELPERS -------------------------------- */
  // get News List :
  async function getNews() {
    const { data } = await axios(API_NEWS(language));
    setNewsList(data);
  }
  // Effect
  useEffect(() => {
    getNews();
  }, []);

  /* ***************************** LOCAL VARIABLES **************************** */
  const pathLanguage = language === 'fr' ? '' : '/en';
  /* ***************************** RENDER HELPERS ***************************** */
  const NewsMap = newsList?.data?.map((newItem) => {
    const {
      id,
      attributes: { title, date, image },
    } = newItem;

    return (
      <Col
        key={id}
        lg={4}
        md={6}
        className="news-section-item "
        onClick={() => {
          navigate(`${pathLanguage}/news/${newItem.id}`);
        }}
      >
        <div className="news-section-item-container">
          <div className="news-item__img">
            <img src={API_UPLOADS(image?.data[0]?.attributes.url)} alt="news" />
          </div>
          <p className="news-item__date">{convertDigitIn(date)}</p>
          <h3 className="news-item__text">{title}</h3>
        </div>
      </Col>
    );
  });

  function GetPagination() {
    return (
      <Row align="center" justify="center" className="pagination-container">
        {PageNumbers.map((pageNum) => (
          <button
            type="button"
            key={pageNum}
            onClick={() => setActivePage(pageNum)}
            className={`${activePage === pageNum ? 'active' : ''}`}
          >
            {pageNum}
          </button>
        ))}
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="news-section " fluid>
      <Row
        align="center"
        justify="center"
        className="news-section-items scroll-reveal"
      >
        {NewsMap}
      </Row>
      <GetPagination />
    </Container>
  );
}

NewsSection.propTypes = {};

NewsSection.defaultProps = {};

export default NewsSection;
